import { Box, Button, makeStyles, Typography } from "@material-ui/core"
import _ from "lodash"
import React from "react"
import theme from "../../../plugins/digic-theme/theme"
import { useShowMoreByYear } from "../utils/useShowMoreByYear"
import { ArticlePreview } from "./article-preview"

const useStyles = makeStyles({
  container: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "1fr 1fr 1fr",
    marginTop: "32px",
    gap: "16px",

    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  wrapper: {
    marginTop: "128px",
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      marginTop: "64px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "32px",
    },
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  titleWrapper: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "0 16px",
    },
  },
})

export const AllArticles = ({ articles }) => {
  const classes = useStyles()
  const articlesByYear = _.groupBy(articles, article =>
    new Date(article.published_date).getFullYear()
  )

  const { slicedItemsByYear, showMore, hasMore } = useShowMoreByYear({
    itemsByYear: articlesByYear,
  })

  const renderSlicedArticles = () =>
    Object.keys(slicedItemsByYear)
      .sort((a, b) => b - a)
      .map(year => (
        <Box className={classes.wrapper} key={year}>
          <Typography variant="h2" className={classes.titleWrapper}>
            <span
              style={{
                backgroundColor: "#f26522",
                padding: "0 8px",
              }}
            >
              {year}
            </span>
          </Typography>

          <Box className={classes.container}>
            {articlesByYear[year].map(article => (
              <ArticlePreview key={article.id} article={article} />
            ))}
          </Box>
        </Box>
      ))

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      {renderSlicedArticles()}
      <Box width="100%" display="flex" justifyContent="center" p={6}>
        {hasMore && (
          <Button variant="contained" onClick={showMore}>
            Show more
          </Button>
        )}
      </Box>
    </Box>
  )
}
