import { Box, makeStyles } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { AllArticles } from "../components/article/all-articles"
import { FeaturedArticles } from "../components/article/featured-articles"
import Layout from "../components/layout/layout-home"

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: "100px",
    maxWidth: "1440px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "100px",
    },
  },
}))
const NewsPage = () => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        articles(sort: "published_date:desc") {
          id
          title
          published_date
          thumbnail {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <Layout>
      <Box className={classes.wrapper}>
        <FeaturedArticles articles={data.strapi.articles} />
        <AllArticles articles={data.strapi.articles} />
      </Box>
    </Layout>
  )
}

export default NewsPage
