import { Box, makeStyles, Typography } from "@material-ui/core"
import { format } from "date-fns"
import Img from "gatsby-image"
import React from "react"
import Truncate from "react-truncate"
import { DigicLink } from "../digic-link"

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: "100%",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      height: "initial",
    },
  },

  container: {
    display: "grid",
    height: "100%",
    "&:hover": {
      filter: "brightness(130%)",
    },
    "& img": {
      transition: "all 800ms ease !important",
    },
    "&:hover img": {
      transform: "scale(1.07)",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(2),
    },
    transition: "all 300ms ease-in-out",
  },
  layerBottom: {
    gridRow: 1,
    gridColumn: 1,
    display: "block",
    aspectRatio: "4/3",

    [theme.breakpoints.down("sm")]: {
      height: "initial",
    },
  },
  layerBottomMain: {
    gridRow: 1,
    gridColumn: 1,
    display: "block",
  },
  layerBottomSide: {
    gridRow: 1,
    gridColumn: 1,
    display: "block",
  },
  image: {
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "56vw",
    },
  },
  layerTop: {
    position: "relative",
    gridRow: 1,
    [theme.breakpoints.down("sm")]: {
      gridRow: 2,
      padding: "0 16px",
    },
    gridColumn: 1,
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  titleWrapper: {
    height: "130px",
    paddingTop: "16px",
    paddingLeft: "30px",
    paddingRight: "30px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
      paddingRight: "0",
    },
    paddingBottom: "30px",
    backgroundColor: "rgba(0,0,0,0.6)",
  },
  title: {
    fontFamily: "Geogrotesque",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "1.2",
    textTransform: "uppercase",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
}))

export const ArticlePreview = ({ article, main, side }) => {
  const classes = useStyles()

  return (
    <DigicLink
      to={`/news/${article.id}`}
      className={side ? classes.sideWrapper : classes.wrapper}
    >
      <Box className={classes.container}>
        <Box
          className={
            main
              ? classes.layerBottomMain
              : side
              ? classes.layerBottomSide
              : classes.layerBottom
          }
        >
          <Img
            fluid={article.thumbnail.imageFile.childImageSharp.fluid}
            className={classes.image}
          />
        </Box>
        <Box className={classes.layerTop}>
          <Box className={classes.titleWrapper}>
            <Typography variant="caption">
              {format(new Date(article.published_date), "dd/MM/yyyy")}
            </Typography>
            <Box>
              <Truncate
                lines={2}
                ellipsis={<span>...</span>}
                className={classes.title}
              >
                {article.title}
              </Truncate>
            </Box>
          </Box>
        </Box>
      </Box>
    </DigicLink>
  )
}
