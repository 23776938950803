import { Box, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { ArticlePreview } from "./article-preview"

const useStyles = makeStyles(theme => ({
  mainNewsContainer: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "1.6fr 1fr",
    minHeight: "700px",
    gap: "16px",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "1fr",
      marginTop: "32px",
    },
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "64px 0",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "16px",
      alignItems: "start",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "16px",
      marginBottom: "0px",
    },
  },
  title: {
    text: "uppercase",
    width: "100%",
  },
  main: {
    gridRow: "span 2",
  },
}))

export const FeaturedArticles = ({ articles }) => {
  const classes = useStyles()
  const [main, side1, side2] = articles

  return (
    <Box display="flex" alignItems="center" flexDirection="column" width="100%">
      <Box className={classes.titleWrapper}>
        <Typography variant="h1" className={classes.title}>
          <span
            style={{
              backgroundColor: "#f26522",
              padding: "0 8px",
            }}
          >
            Recent news
          </span>
        </Typography>
      </Box>
      <Box className={classes.mainNewsContainer}>
        {main && (
          <Box className={classes.main}>
            <ArticlePreview article={main} main />
          </Box>
        )}

        {side1 && (
          <ArticlePreview
            className={classes.sideNewsContainter}
            article={side1}
            side
          />
        )}
        {side2 && <ArticlePreview article={side2} side />}
      </Box>
    </Box>
  )
}
